import loadable from '@loadable/component';
import React, { Fragment } from 'react';
import { Contentful } from 'schema/Contentful';

export interface SectionsProps {
  sections?: Contentful.ContentfulEntry[];
}

const LinkedItemAsync = loadable(() => import('@components/connected/LinkedItem'));

const Sections: React.FC<SectionsProps> = ({ sections }) => {
  if (!sections || !sections.length) {
    return null;
  }

  return (
    <Fragment>
      {sections.map((item, index) => (
        <LinkedItemAsync key={item.id ?? ''} index={index} item={item} />
      ))}
    </Fragment>
  );
};

export default Sections;
